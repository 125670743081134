var _a, _b;
var detect = require('detect-browser').detect;
var parseOptions = function (str) {
    try {
        return JSON.parse(str);
    }
    catch (e) {
        console.warn('Failed parsing browserWarningBlackList JSON, assuming browser warning is disabled.');
        return false;
    }
};
var loadScript = function (url, callback) {
    if (callback === void 0) { callback = null; }
    var head = document.head;
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    // There are several events for cross browser compatibility.
    script.onreadystatechange = callback;
    script.onload = callback;
    head.appendChild(script);
};
var setCookie = function (name, value, days, path) {
    if (path === void 0) { path = '/'; }
    var expires = new Date(Date.now() + days * 864e5).toUTCString();
    document.cookie =
        name +
            '=' +
            encodeURIComponent(value) +
            '; expires=' +
            expires +
            '; path=' +
            path;
};
var getCookie = function (name) {
    return document.cookie.split('; ').reduce(function (r, v) {
        var parts = v.split('=');
        return parts[0] === name ? decodeURIComponent(parts[1]) : r;
    }, '');
};
var showedOnceAlready = function (opt) {
    if (!opt.showOnce) {
        return true;
    }
    var showedOnce = getCookie(opt.showOnceCookieName);
    if (!showedOnce) {
        setCookie(opt.showOnceCookieName, true, opt.showOnceCookieDays);
        return false;
    }
    return true;
};
var isUnsupportedBrowser = function (browser, browserBlackList) {
    // Check if we get browser and not 'bot', 'node', etc.
    if (browser.type !== 'browser') {
        return false;
    }
    // Check if browser is blacklisted and get minimum version
    var found = Object.keys(browserBlackList).find(function (b) { return b.toLowerCase() === browser.name; });
    if (!found) {
        return false;
    }
    var minVersion = browserBlackList[found];
    // Check if browser version is lower than minimum version
    // For now we only support major version
    return minVersion && parseInt(browser.version, 10) <= parseInt(minVersion, 10)
        ? true
        : false;
};
var options = parseOptions((_b = (_a = window['__DOTCOM_ENV_CONFIG']) === null || _a === void 0 ? void 0 : _a.flags) === null || _b === void 0 ? void 0 : _b.browserWarningOptions);
if (options) {
    window['browserWarningOptions'] = options;
    var browser = detect();
    if (isUnsupportedBrowser(browser, options.browserBlackList)) {
        console.warn('Unsupported browser', browser, "Min supported version: ".concat(parseInt(options.browserBlackList[browser.name], 10) + 1));
        if (!showedOnceAlready(options)) {
            window['browserWarningOptions'].iOS = browser.os === 'iOS';
            window['browserWarningOptions'].android = browser.os === 'Android OS';
            window['browserWarningOptions'].hasSafari = ['iOS', 'Mac OS'].includes(browser.os);
            loadScript(options.warningScriptUrl);
        }
    }
}
